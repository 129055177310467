import React from "react";
import { useNavigate } from 'react-router-dom'; 
export default function Select_layers() {

       const navigate = useNavigate(); 
    const handlegotomarkerfestival = () => {
        navigate('/Festival_edit'); 
    };
    const handlegotomarkerdisableperson = () => {
        navigate('/Disableperson_edit'); 
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <div className="text-2xl font-bold mb-4">เลือกชั้นข้อมูล</div>
            <button type="button" 
            disabled
            onClick={handlegotomarkerfestival}
            className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                ตำแหน่งงานเทศกาล
            </button>
            <button 
            onClick={handlegotomarkerdisableperson}
            type="button" 
            className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
            ตำแหน่งคนพิการ</button>
        </div>
    );
}