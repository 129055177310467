import React, { useEffect, useState } from "react";
import { readMarkers, createMarker, updateMarker, deleteMarker } from "./markerService";
import { useNavigate } from 'react-router-dom'; 

export default function Festival_edit() {
    const [markers, setMarkers] = useState([]);
    const [newMarker, setNewMarker] = useState({
        location_name: "",
        address: "",
        latitude: "",
        longitude: "",
        activities: [] // Default empty activities array
    });
    const [editableMarker, setEditableMarker] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [markersPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState(""); // New state for search

    const navigate = useNavigate(); 

    useEffect(() => {
        loadMarkers();
    }, []);

    const loadMarkers = async () => {
        try {
            const result = await readMarkers();
            setMarkers(result);
        } catch (error) {
            console.error('Error loading markers:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            const result = await deleteMarker(id);
            alert(result.message);
            if (result.status === 'ok') {
                loadMarkers();
                if (currentPage > 1 && markers.length % markersPerPage === 1) {
                    setCurrentPage(currentPage - 1);
                }
            }
        } catch (error) {
            console.error('Error deleting marker:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (editableMarker) {
            setEditableMarker((prev) => ({ ...prev, [name]: value }));
        } else {
            setNewMarker((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        if (!validateForm(newMarker)) return;

        try {
            const result = await createMarker(newMarker);
            alert(result.message);
            if (result.status === 'ok') {
                loadMarkers();
                setNewMarker({ location_name: "", address: "", latitude: "", longitude: "", activities: [] });
                setIsCreating(false);
            }
        } catch (error) {
            console.error('Error creating marker:', error);
        }
    };

    const handleEdit = (marker) => {
        setEditableMarker(marker);
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (!validateForm(editableMarker)) return;

        try {
            const result = await updateMarker(editableMarker);
            alert(result.message);
            if (result.status === 'ok') {
                loadMarkers();
                setEditableMarker(null);
            }
        } catch (error) {
            console.error('Error updating marker:', error);
        }
    };

    const validateForm = (marker) => {
        if (!marker.location_name || !marker.address || isNaN(marker.latitude) || isNaN(marker.longitude)) {
            alert('Please fill in all fields with valid values.');
            return false;
        }
        return true;
    };

    const handleCancelEdit = () => {
        setEditableMarker(null);
    };

    const indexOfLastMarker = currentPage * markersPerPage;
    const indexOfFirstMarker = indexOfLastMarker - markersPerPage;

    // Filter markers based on search query
    const filteredMarkers = markers.filter(marker =>
        marker.location_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const currentMarkers = filteredMarkers.slice(indexOfFirstMarker, indexOfLastMarker);
    const totalPages = Math.ceil(filteredMarkers.length / markersPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleBackToMap = () => {
        navigate('/Rendermap'); 
    };

    const handleAdvanceEdit = () => {
        navigate(`/ActivitiesEdit`);
    };

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="container mx-auto mt-4">
                <div className="bg-white shadow-md rounded-lg p-6">
                    <div className="flex justify-between mb-4">
                        <h2 className="text-2xl font-bold text-blue-600">Markers Festival</h2>
                        <button 
                            onClick={() => setIsCreating(!isCreating)}
                            className="bg-blue-500 text-white py-2 px-4 rounded">
                            {isCreating ? "CANCEL" : "CREATE"}
                        </button>
                        <button 
                            type="button" 
                            onClick={handleAdvanceEdit} 
                            className="bg-gray-500 text-white py-2 px-4 rounded ml-2">
                            Advance Edit
                        </button>
                    </div>

                    {/* Search Input */}
                    <input 
                        type="text" 
                        placeholder="Search by Location" 
                        value={searchQuery} 
                        onChange={(e) => setSearchQuery(e.target.value)} 
                        className="border p-2 mb-4 w-full"
                    />

                    {isCreating && (
                        <form onSubmit={handleCreate} className="mb-4">
                            <input type="text" name="location_name" placeholder="Location" value={newMarker.location_name} onChange={handleInputChange} required />
                            <input type="text" name="address" placeholder="Address" value={newMarker.address} onChange={handleInputChange} required />
                            <input type="text" name="latitude" placeholder="Latitude" value={newMarker.latitude} onChange={handleInputChange} required />
                            <input type="text" name="longitude" placeholder="Longitude" value={newMarker.longitude} onChange={handleInputChange} required />
                            <button type="submit" className="bg-green-500 text-white py-2 px-4 rounded">Save</button>
                        </form>
                    )}

                    {editableMarker && (
                        <form onSubmit={handleUpdate} className="mb-4">
                            <input type="text" name="location_name" placeholder="Location" value={editableMarker.location_name} onChange={handleInputChange} required />
                            <input type="text" name="address" placeholder="Address" value={editableMarker.address} onChange={handleInputChange} required />
                            <input type="text" name="latitude" placeholder="Latitude" value={editableMarker.latitude} onChange={handleInputChange} required />
                            <input type="text" name="longitude" placeholder="Longitude" value={editableMarker.longitude} onChange={handleInputChange} required />
                            <button type="submit" className="bg-yellow-500 text-white py-2 px-4 rounded">Update</button>
                            <button type="button" onClick={handleCancelEdit} className="bg-gray-500 text-white py-2 px-4 rounded ml-2">Cancel</button>
                        </form>
                    )}

                    <div className="overflow-x-auto">
                        <table className="min-w-full border-collapse border border-gray-300">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="border px-4 py-2">Location</th>
                                    <th className="border px-4 py-2">Address</th>
                                    <th className="border px-4 py-2">Latitude</th>
                                    <th className="border px-4 py-2">Longitude</th>
                                    <th className="border px-4 py-2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentMarkers.map((marker) => (
                                    <tr key={marker.id} className="hover:bg-gray-100">
                                        <td className="border px-4 py-2">{marker.location_name}</td>
                                        <td className="border px-4 py-2">{marker.address}</td>
                                        <td className="border px-4 py-2">{marker.latitude}</td>
                                        <td className="border px-4 py-2">{marker.longitude}</td>
                                        <td className="border px-4 py-2">
                                            <button className="bg-yellow-500 text-white px-2 py-1 rounded mr-2" onClick={() => handleEdit(marker)}>Edit</button>
                                            <button className="bg-red-500 text-white px-2 py-1 rounded" onClick={() => handleDelete(marker.id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    
                    {/* Pagination Controls */}
                    <div className="flex justify-between mt-4">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1} className="bg-gray-500 text-white py-2 px-4 rounded">Previous</button>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages} className="bg-gray-500 text-white py-2 px-4 rounded">Next</button>
                    </div>
                    <button onClick={handleBackToMap} className="bg-blue-500 text-white py-2 px-4 rounded mt-4">
                        Back to Map
                    </button>
                </div>
            </div>
        </div>
    );
}
