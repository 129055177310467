import React, { useEffect, useState } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import { Icon } from 'leaflet';

const customIcon = new Icon({
    iconUrl: "https://cdn.pixabay.com/photo/2015/05/09/02/51/handicap-759184_1280.png",
    iconSize: [38, 38], // Size of the icon
});

const MapMarkersdisableperson = () => {
    const [markers, setMarkers] = useState([]);
// Fetch markers from the backend
const fetchMarkers = async () => {
    try {
        const response = await fetch(
            'https://www.chaipongmap.com/read_markers_disable_person.php',
            // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/read_markers_disable_person.php', 
            {
                method: 'GET', // ใช้ method 'GET' ในการดึงข้อมูล
                headers: {
                    'X-API-KEY': 'Chaipong', // API Key ที่คุณต้องการส่ง
                    'Content-Type': 'application/json' // เพื่อระบุประเภทของข้อมูลที่รับ
                }
            }
        );

        const data = await response.json();
        console.log(data);

        if (data.status === "success") {
            setMarkers(data.data); // Set the markers with the data array
        } else {
            console.error('Error:', data.message);
        }
    } catch (error) {
        console.error('Error fetching markers:', error);
    }
};


    useEffect(() => {
        fetchMarkers();
    }, []);

    const map = useMapEvents({
        click: () => {
            map.closePopup();
        }
    });

    return (
        <>
            {markers.map((marker, index) => (
                <Marker
                    key={index}
                    position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]} // Convert lat and long to numbers
                    icon={customIcon}
                    // eventHandlers={{
                    //     click: () => {
                    //         map.setView(
                    //             [
                    //                 parseFloat(marker.latitude),
                    //                 parseFloat(marker.longitude),
                    //             ],
                    //             14 // Zoom level
                    //         );
                    //     }
                    // }}
                >
                    <Popup>
                        {/* <h2>{marker.villagename}</h2> */}
                        <p><strong>หมู่ :</strong> {marker.villagenumber}</p>
                        <p><strong>บ้านเลขที่ :</strong> {marker.housenumber}</p>
                        <p><strong>ตำบล:</strong> {marker.tambon}</p>
                        <p><strong>อำเภอ:</strong> {marker.amphoe}</p>
                        <p><strong>จังหวัด:</strong> {marker.province}</p>
                        <p><strong>ชื่อ:</strong> {marker.prefix} {marker.firstname} {marker.lastname}</p>
                        <p><strong>ลักษณะความพิการ:</strong> {marker.typeofdisability}</p>
                        <p><strong>อายุ:</strong> {marker.age}</p>
                        <p><strong>หมายเหตุ:</strong> {marker.remarks || "ไม่ระบุ"}</p>
                    </Popup>
                </Marker>
            ))}
        </>
    );
};

export default MapMarkersdisableperson;
