import React from 'react';
import { MapContainer, TileLayer, LayersControl, Popup, useMapEvents, useMap, Marker } from 'react-leaflet';
import { Icon } from 'leaflet';
import { BiTargetLock } from "react-icons/bi";  // แก้ไขการ import icon
import 'leaflet/dist/leaflet.css';
import MapMarkersfestival from './databaserender-festival';
import MapMarkersdisableperson from './databaserender-disable-person';

const { BaseLayer } = LayersControl;

// Component สำหรับจับคลิกขวาและแสดง Popup
const RightClickHandler = () => {
  const [position, setPosition] = React.useState(null);

  useMapEvents({
    contextmenu: (e) => {
      setPosition(e.latlng);
    },
  });

  return position === null ? null : (
    <Popup position={position} onClose={() => setPosition(null)}>
      <div>
        <p>ละติจูด: {position.lat.toFixed(6)}</p>
        <p>ลองจิจูด: {position.lng.toFixed(6)}</p>
      </div>
    </Popup>
  );
};

// Component สำหรับปุ่มและการแสดงตำแหน่งปัจจุบัน
const LocationButton = () => {
  const [position, setPosition] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const map = useMap();

  const handleLocationClick = () => {
    setLoading(true);
    map.locate().on('locationfound', function(e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
      setLoading(false);
    }).on('locationerror', function(e) {
      console.error(e);
      alert('ไม่สามารถระบุตำแหน่งของคุณได้ กรุณาตรวจสอบการอนุญาตการเข้าถึงตำแหน่ง');
      setLoading(false);
    });
  };

  return (
    <div className="leaflet-top leaflet-right" style={{ marginTop: '70px', marginRight: '10px' }}>
      <div className="leaflet-control leaflet-bar">
        <button
          onClick={handleLocationClick}
          disabled={loading}
          style={{
            width: '32px',
            height: '32px',
            background: 'white',
            border: '2px solid rgba(0,0,0,0.2)',
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0',
            transition: 'background-color 0.2s'
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f4f4f4'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
        >
          {loading ? (
            <span style={{ fontSize: '20px' }}>⌛</span>
          ) : (
            <BiTargetLock size={20} style={{ color: '#333' }} />
          )}
        </button>
      </div>
      {position && (
        <Marker 
          position={position}
          icon={new Icon({
            iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
            iconSize: [35, 35],
            iconAnchor: [17, 35]
          })}
        >
          <Popup>
            <div>
              <p>ตำแหน่งของคุณ</p>
              <p>ละติจูด: {position.lat.toFixed(6)}</p>
              <p>ลองจิจูด: {position.lng.toFixed(6)}</p>
            </div>
          </Popup>
        </Marker>
      )}
    </div>
  );
};

const MapComponent = React.forwardRef(({ showMarker1, showMarker2 }, ref) => {
  return (
    <MapContainer
      ref={ref}
      style={{ height: "100%", width: "100%", zIndex: "30" }}
      center={[16.7619778601, 100.1865224515]}
      zoom={14}
      zoomControl={false}
    >
      <RightClickHandler />
      <LocationButton />
      <LayersControl position="topright">
        <BaseLayer name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </BaseLayer>
        <BaseLayer name="Alidade Satellite" checked>
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
          />
        </BaseLayer>
        {showMarker1 && <MapMarkersfestival />}
        {showMarker2 && <MapMarkersdisableperson />}
      </LayersControl>
    </MapContainer>
  );
});

export default MapComponent;