// src/markerService.js
// Base URL for your API

const API_KEY = 'Chaipong';

export const fetchMarkersActivities = async () => {
  try {
      const response = await fetch("https://www.chaipongmap.com/activities_markers.php", {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'X-API-KEY': API_KEY
          }
      });

      if (!response.ok) {
          throw new Error('Failed to fetch markers');
      }
      return await response.json();
  } catch (error) {
      console.error('Error fetching markers:', error);
      throw error;
  }
};

export const readMarkers = async () => {
    try {
        const response = await fetch("https://www.chaipongmap.com/read_markers.php", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch markers');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching markers:', error);
        throw error;
    }
};

export const createMarker = async (newMarker) => {
  try {
      const response = await fetch('https://www.chaipongmap.com/create_marker.php', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'X-API-KEY': API_KEY
          },
          body: JSON.stringify(newMarker),
      });
      if (!response.ok) {
          throw new Error('Failed to create marker');
      }
      return await response.json();
  } catch (error) {
      console.error('Error creating marker:', error);
      throw error;
  }
};

export const updateMarker = async (editableMarker) => {
  try {
      const response = await fetch('https://www.chaipongmap.com/update_marker.php', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'X-API-KEY': API_KEY
          },
          body: JSON.stringify(editableMarker),
      });
      if (!response.ok) {
          throw new Error('Failed to update marker');
      }
      return await response.json();
  } catch (error) {
      console.error('Error updating marker:', error);
      throw error;
  }
};

export const deleteMarker = async (id) => {
  try {
      console.log("Deleting marker with ID:", id); // Debugging line
      const response = await fetch('https://www.chaipongmap.com/delete_marker.php', {
          method: 'DELETE',
          headers: {
              'Content-Type': 'application/json',
              'X-API-KEY': API_KEY
          },
          body: JSON.stringify({ id }) // Ensure the ID is sent in the body
      });

      const data = await response.json();

      console.log("Response from delete marker:", data); // Debugging line

      if (data.status === 'error') {
          console.error('Error deleting marker:', data.message);
          throw new Error(data.message);
      }

      return data;
  } catch (error) {
      console.error('Error deleting marker:', error);
      throw error;
  }
};

export const readMarkers_disableperson = async () => {
    try {
        const response = await fetch("https://www.chaipongmap.com/read_markers_disable_person.php", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch markers');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching markers:', error);
        throw error;
    }
};
