import React, { useEffect, useState } from "react";
import { readMarkers, createMarker, updateMarker, deleteMarker } from "./markerService";
import { useNavigate } from 'react-router-dom'; 

export default function Disableperson_edit() {
    const navigate = useNavigate();
    const handleBackToMap = () => {
        navigate('/Rendermap'); 
    };

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <h3>กำลังปรับปรุง ...</h3>
            <button onClick={handleBackToMap} className="bg-blue-500 text-white py-2 px-4 rounded mt-4">
                Back to Map
            </button>
        </div>
    );
}