import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar'; // Adjust the import path as necessary
import MapComponent from './MapContainer'; // Adjust the import path as necessary
import ButtonEditdata from './ButtonEditdata'; // Import the button component
import { useNavigate } from 'react-router-dom';

const Rendermap = () => {
  const [showMarker1, setShowMarker1] = useState(false);
  const [showMarker2, setShowMarker2] = useState(true);
  const navigate = useNavigate();
  const mapRef = useRef(); // สร้าง ref สำหรับ MapContainer

  const toggleMarker1 = () => setShowMarker1(!showMarker1);
  const toggleMarker2 = () => setShowMarker2(!showMarker2);

  // ฟังก์ชันสำหรับซูมไปยังตำแหน่งที่กำหนด
  const zoomToMarker1 = () => {
    const map = mapRef.current; // เข้าถึง map instance
    if (map) {
      map.setView([17.09483155, 99.81838832], 10); // พิกัดกรุงเทพตัวอย่าง
    }
  };

  const zoomToMarker2 = () => {
    const map = mapRef.current;
    if (map) {
      map.setView([16.7619778601, 100.1865224515], 14); // พิกัดประเทศไทยตัวอย่าง
    }
  };

  // Simulated authentication check (replace with your own logic)
  const isAuthenticated = true; // Change to your authentication logic

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/', { replace: true }); // Redirect to login if not authenticated
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-grow">
        <Sidebar 
          showMarker1={showMarker1} 
          toggleMarker1={toggleMarker1} 
          showMarker2={showMarker2} 
          toggleMarker2={toggleMarker2} 
          zoomToMarker1={zoomToMarker1} 
          zoomToMarker2={zoomToMarker2} 
        />
        <div className="flex-grow relative">
          <MapComponent ref={mapRef} showMarker1={showMarker1}  showMarker2={showMarker2}  />
          <div className="absolute bottom-4 right-4 z-50">
            {/* <ButtonEditdata /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rendermap;