import React, { useEffect, useState } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import { Icon } from 'leaflet';
// import './App.css'
const customIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/9131/9131546.png",
    iconSize: [38, 38], // Size of the icon
});
const MapMarkersfestival = () => {
    const [markers, setMarkers] = useState([]);

    // Fetch markers from the backend
    const fetchMarkers = async () => {
        try {
            const response = await fetch('https://www.chaipongmap.com/activities_markers.php', { // Adjust your API endpoint
            
            method: 'GET',
                headers: {
                    'X-API-KEY': 'Chaipong' // ส่ง API Key ใน header
                }
            });

            const data = await response.json();
            console.log(data); // สำหรับดูข้อมูลที่ได้รับจาก API
            setMarkers(data); // Assuming the response contains an array of markers
        } catch (error) {
            console.error('Error fetching markers:', error);
        }
    };

    useEffect(() => {
        fetchMarkers();
    }, []);

    const map = useMapEvents({
        click: () => {
          map.closePopup();
        }
      });

    return (
        <>
            {markers.map((marker, index) => (
               
                <Marker
                    key={index}
                    position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]} // Convert lat and long to numbers
                    icon={customIcon}
                    eventHandlers={{
                        click: () => {
                          map.setView(
                            [
                                marker.latitude,
                                marker.longitude,
                            ],
                            14 // Zoom level
                          );
                        }
                      }}
                >
                    <Popup>
                        <h2>Location : {marker.Location}</h2>
                        <p>Address : {marker.address}</p>
                        <p>Latitude : {marker.latitude}</p>
                        <p>Longitude : {marker.longitude}</p>

                        {/* Table to display activities */}
                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead>
                                <tr>
                                    <th style={{ padding: '8px', borderRight: '1px solid black', borderBottom: '1px solid black'}}>Date</th>
                                    <th style={{ padding: '8px', borderBottom: '1px solid black'}}>Activity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {marker.activities && marker.activities.map((activity, i) => (
                                    <tr key={i}>
                                        <td style={{ padding: '8px', borderRight: '1px solid black' }}>{activity.date}</td>
                                        <td style={{ padding: '8px' }}>{activity.activities}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Popup>
                </Marker>
            ))}
        </>
    );
};

export default MapMarkersfestival;
